<template>
  <div
    class="styled-box rounded-xl border-2"
    :class="{
      'border-white': !$attrs?.class?.includes('border-'),
    }"
  >
    <slot classes="p-5 md:p-6" />
  </div>
</template>

<style scoped>
.styled-box {
  background-image: linear-gradient(
    227.31deg,
    rgba(255, 255, 255, 0.65) -6.59%,
    rgba(255, 255, 255, 0.4225) 114.8%
  );
}
</style>
